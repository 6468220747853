<template>
    <div v-loading="loading" class="right-content">
        <div class="mod-block" style="margin-top: 20px;">
            <span>职位</span>
            <input v-model="position"/>
        </div>
        <div class="mod-block">
            <span>薪资范围</span>
            <input v-model="salary"/>
        </div>
        <div class="mod-block">
            <span>公司名称</span>
            <input v-model="company_name"/>
        </div>
        <div class="mod-block">
            <span>证书补贴</span>
            <input v-model="certificate_subsidy"/>
        </div>
        <div class="mod-block">
            <span>地点</span>
            <input v-model="place"/>
        </div>
        <div class="btn" @click="next">发布招聘</div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props:{
        type:{
            type:String,
            default:() => 'xuesheng',
        },
    },
    computed: mapState([
        'userInfo',
        'daohangConfig',
    ]),
    data() {
        return {
            loading: false,
            position:'',
            salary:'',
            company_name:'',
            certificate_subsidy:'',
            place:''
        }
    },
    methods: {
        // 添加企业库
        async next(){
            if(!Object.hasOwnProperty.call(this.userInfo,'role_id')){
                this.$router.push('/login');
                return false;
            }else if(Number(this.userInfo.role_id)!==1){
                this.$toast('当前账号非企业用户，无发布权限');
                return false;
            }
            if(this.position === ''){
                this.$toast('请输入职位');
                return false;
            }
            if(this.salary === ''){
                this.$toast('请输入薪资范围');
                return false;
            }
            if(this.company_name === ''){
                this.$toast('请输入公司名称');
                return false;
            }
            if(this.certificate_subsidy === ''){
                this.$toast('请输入证书补贴');
                return false;
            }
            if(this.place === ''){
                this.$toast('请输入地点');
                return false;
            }
            const params = {
                position: this.position,
                salary: this.salary,
                company_name: this.company_name,
                certificate_subsidy: this.certificate_subsidy,
                place: this.place,
            }
            this.loading = true;
            const res = await this.$http.post('/api/user/addCompany', params)
            this.loading = false;
            if(res){
                this.$toast('提交成功');
                window.location.reload();
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.right-content{
    width: 600px;
    .mod-block{
        span{
            display: inline-block;
            width: 65px;
        }
        width:280px;
        margin:auto;
        margin-bottom: 10px;
        font-size: 14px;
        color: #666666;
    }
}
.btn{
    width: 100px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #3884D3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    margin-top:20px;
    cursor: pointer;

}
</style>
