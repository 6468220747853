<template>
    <div class="left-content" style="width:600px">
        <table v-if="type==='student'" class="table-block" border-collapse="collapse" cellspacing="0">
            <tr class="title">
                <th style="width:12%">姓名</th>
                <th style="width:23%">期望职位</th>
                <th style="width:20%">学校</th>
                <th style="width:20%">专业</th>
                <th style="width:25%">求职地点</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.position}}</td>
                <td>{{item.school}}</td>
                <td>{{item.major}}</td>
                <td>{{item.workplace}}</td>
            </tr>
        </table>
        <table v-if="type!=='student'" class="table-block" border-collapse="collapse" cellspacing="0" >
                <tr class="title">
                    <th style="width:23%">职位</th>
                    <th style="width:17%">薪资</th>
                    <th style="width:20%">公司名称</th>
                    <th style="width:20%">证书补贴</th>
                    <th style="width:20%">地点</th>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{item.position}}</td>
                    <td>{{item.salary}}</td>
                    <td>{{item.company_name}}</td>
                    <td>{{item.certificate_subsidy}}</td>
                    <td>{{item.place}}</td>
                </tr>
            </table>
        <el-pagination
            v-if="Number(total)!==0"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="Number(total)"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>
<script>
export default {
    props:{
        type:{
            type:String,
            defalut:() => 'student',
        }
    },
    data() {
        return {
            studentList:[],
            total:0,
            list:[],
            page:1,
            limit:10,
        }
    },
    beforeMount() {
        this.getData();
    },
    methods: {
        slectType(type){
            this.type = type;
            this.page = 1;
            this.limit = 10;
            this.getData();
        },
        handleSizeChange(size){
            this.limit = size;
            this.getData();
        },
        handleCurrentChange(page){
            this.page = page;
            this.getData();
        },
        async getData(){
            const params = {
                page:this.page,
                limit: this.limit,
                type: Number(this.type),
            }
            let res;
            if(this.type==='student'){
                res = await this.$http.get('/api/index/getStudentList',params);
            }else{
                res = await this.$http.get('/api/index/getCompanyList',params);
            }
            if(res){
                this.list = res.list;
                this.total = res.total;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.el-pagination{
    color:#000;
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    span{
        font-size: 16px !important;
    }
    span:not([class*=suffix]){
        font-size: 19px !important;
    }
}
.type-list{
    display: flex;
    flex-direction: row;
    span{
        display: block;
        cursor: pointer;
        height: 49px;
        line-height: 49px;
        font-size: 20px;
        color: #333;
        margin-right: 20px;
        padding: 0 10px;
        font-weight: 400;
    }
    .select{
        font-weight: bold;
        border-bottom: 2px solid #224899;
        color: #224899;
    }
    span:hover{
        color:#224899;
    }
}
a {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    display: block;
    span {
        font-size: 14px;
        float: right;
        color: #999;
    }
}
a:hover{
    color:#326fd4
}

.table-block{
    margin-top: 20px;
    font-size: 14px;
    table-layout:fixed;
    width: 100%;
    .title{
        background: #FAFBFE;
        color: rgb(49, 48, 48);
        height: 30px !important;
    }
    tr{
        height: 25px;
        width: 100%;
        th{
            padding-right: 10px;
            padding-left: 5px;
            text-align: left;
        }
        td{
            padding-right: 10px;
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break:keep-all;
        }
    }
}
</style>
